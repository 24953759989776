import { useState, useEffect } from 'react';

import api from 'utils/api';
import useGlobalState from 'utils/global-state';
import useHttpClient from 'utils/hooks/http-request';
import { UPDATE_USER_INFO, LOGOUT, PLAN_GOT_INTERVIEW } from 'utils/constants';

import DataProcessingPage from 'components/pages/data-processing';
import MainTemplate from 'components/templates/main-template';
import Spinner from 'components/atoms/spinner';
import ErrorPage from 'components/pages/error';

const PrivateInterviewSuccess = () => {
  const [state, dispatch] = useGlobalState();
  const { sendRequest } = useHttpClient();
  const [showErrorPage, setShowErrorPage] = useState(false);

  useEffect(() => {
    if (state.userData.planState !== PLAN_GOT_INTERVIEW && state.userData.timId === null) {
      sendRequest(api.changeStateToGotInterview(), 'POST').then(() => {
        getPortraitInfo();
      });
    } else {
      getPortraitInfo();
    }
  }, [state.userData.planState]);

  const planState = [PLAN_GOT_INTERVIEW];
  const getPortraitInfo = async () => {
    const getInfoUri = api.getPortraitInfo();

    try {
      const response = await sendRequest(getInfoUri);
      if (response.success) {
        const { success } = response;

        dispatch({
          type: UPDATE_USER_INFO,
          payload: {
            timId: success.tim_id,
            avatar: success.avatar,
            isVerified: success.verified,
            hasGoogle: success.hasGoogle,
            hasFacebook: success.hasFacebook,
            calendly: success.hasCalendly,
            shareKey: success.public_key,
            planState: success.plan_state,
            stateMeta: success.state_meta,
          },
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else {
        setShowErrorPage(true);
      }
    }
  };

  if (showErrorPage) {
    return <ErrorPage />;
  }

  if (planState.includes(state.userData.planState)) return <DataProcessingPage />;
  return (
    <MainTemplate>
      <div className="icon-text-page">
        <Spinner />
        <p className="icon-text-page-text text-l">Processing data...</p>
      </div>
    </MainTemplate>
  );
};

export default PrivateInterviewSuccess;
