import React from 'react';
import AuthenticationTemplate from 'components/templates/authentication-template';

const EnglishInterviewSuccess = () => (
  <AuthenticationTemplate
    isHeaderExtended={false}
    title="You're awesome! Thank you!"
    withoutFooterLinks
  >
    <div className="interview-success-page">
      <p>
        You can relax and expect an email with a link to your HRMNY ID soon.
      </p>
      <p>Sincerely yours, HRMNY AI SIA.</p>
    </div>
  </AuthenticationTemplate>
);

export default EnglishInterviewSuccess;
