import React, { useState } from 'react';
import { openPopupWidget } from 'react-calendly';
import ButtonPrimary from 'components/atoms/button-primary';
import InviteFriendsModal from 'components/organisms/invite-friends-modal';
import MainTemplate from 'components/templates/main-template';
import useGlobalState from 'utils/global-state';
import {
  PLAN_MEDIA_ACCEPTED,
  PLAN_READY_FOR_MEETING,
  PLAN_MEETING_BOOKED,
  PLAN_MEETING_READY,
  UPDATE_USER_INFO,
} from 'utils/constants';
import './data-processing.scss';
import { ReactComponent as DataProcessing } from 'assets/illustrations/welcome_data_processing.svg';
import { ReactComponent as DataAccepted } from 'assets/illustrations/welcome_data_accepted.svg';
import { ReactComponent as ReadyToBook } from 'assets/illustrations/welcome_book_meeting.svg';
import { ReactComponent as MeetingBooked } from 'assets/illustrations/welcome_meeting_booked.svg';
import useHttpClient from 'utils/hooks/http-request';
import api from 'utils/api';
import useQuery from 'utils/hooks/query';
import Spinner from 'components/atoms/spinner';
import useInterval from 'utils/hooks/set-interval';
import { useHistory } from 'react-router-dom';

const DataProcessingPage = () => {
  const history = useHistory();
  const [{ userData }, dispatch] = useGlobalState();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { sendRequest, isLoading } = useHttpClient();

  const isCalendlyRedirectEvent = useQuery().get('event') === 'calendly_redirect';
  const pingInfoDelayMs = 7000;

  const getPortraitInfo = async () => {
    const getInfoUri = api.getPortraitInfo();

    try {
      const response = await sendRequest(getInfoUri);
      let success;

      if (response.success) {
        success = response.success;

        if (success.plan_state !== PLAN_READY_FOR_MEETING) {
          dispatch({
            type: UPDATE_USER_INFO,
            payload: {
              planState: success.plan_state,
            },
          });
          history.push('/home');
        }
      }
    } catch (error) {
      history.push('/home');
    }
  };

  const bookMeeting = async () => {
    let link = '';

    if (process.env.REACT_APP_ENV === 'STAGE') {
      link = 'https://calendly.com/hrmny/60min';
    } else {
      const userInfo = await sendRequest(api.getPortraitInfo());
      link = userInfo?.success?.lang === 'ENG'
        ? 'https://calendly.com/hrmny-eng/1on1-hrmny-eng'
        : 'https://calendly.com/hrmny-team/1on1-hrmny-rus';
    }

    openPopupWidget(calendlyWidgetOptions(link));
  };

  const calendlyWidgetOptions = (link) => {
    return {
      url: link,
      prefill: {
        name: userData.name,
        email: userData.email,
      },
      pageSettings: {
        primaryColor: '302745',
        textColor: '302745',
      },
    };
  };

  const getMeetingTime = () => {
    const dateOptions = {
      timeZone: userData.calendly.timezone,
      timeZoneName: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };

    return new Date(userData.calendly.event_start).toLocaleString(
      'en-GB',
      dateOptions,
    );
  };

  const pageContent = () => {
    if (
      userData.planState === PLAN_MEDIA_ACCEPTED
            || userData.planState === PLAN_MEETING_READY
    ) {
      return (
        <>
          <h2>Your data has been accepted! Working on your profile.</h2>
          <p className="data-processing-precision-text">
            As soon as your HRMNY ID is ready you will get an email, this process may
            take up to 5 business days. Make sure to keep an eye on your inbox.
          </p>
          <ButtonPrimary
            onClick={() => setIsModalOpened(true)}
            text="Invite your friends"
          />
        </>
      );
    } if (userData.planState === PLAN_READY_FOR_MEETING) {
      return isCalendlyRedirectEvent ? (
        <>
          <div className="data-processing-loader">
            <Spinner />
          </div>
          <p className="data-processing-precision-text">
            Processing data...
          </p>
        </>
      ) : (
        <>
          <h2>Your data has been accepted! Now book a 1-on-1 meeting.</h2>
          <p className="data-processing-precision-text">
            You will be notified once your data is accepted to work on creating your
            profile.
          </p>
          <ButtonPrimary
            onClick={() => bookMeeting()}
            text="Schedule 1-on-1 meeting"
            buttonClass="button-advanced"
          />
        </>
      );
    } if (userData.planState === PLAN_MEETING_BOOKED) {
      return (
        <>
          <h2>Your meeting has been scheduled!</h2>
          <div className="meeting-date-box">{getMeetingTime()}</div>
          <p
            className="calendly-link"
            onClick={() => openPopupWidget(calendlyWidgetOptions(userData.calendly.reschedule_url))}
          >
            Reschedule meeting
          </p>
        </>
      );
    }
    return (
      <>
        <h2>
          We are processing your data to ensure that quality meets the requirements.
        </h2>
        <p className="data-processing-precision-text">
          You will be notified once your data is accepted to work on creating your
          profile.
        </p>
        <ButtonPrimary
          onClick={() => setIsModalOpened(true)}
          text="Invite your friends"
        />
      </>
    );
  };

  useInterval(() => {
    getPortraitInfo();
  }, isCalendlyRedirectEvent && !isLoading && userData.planState === PLAN_READY_FOR_MEETING
    ? pingInfoDelayMs
    : null);

  return (
    <MainTemplate>
      <InviteFriendsModal
        isModalOpened={isModalOpened}
        closeModal={() => setIsModalOpened(false)}
      />
      <div className="data-processing-page">
        <div className="data-processing-page-content">
          {userData.planState === PLAN_MEDIA_ACCEPTED ? (
            <DataAccepted />
          ) : userData.planState === PLAN_READY_FOR_MEETING ? (
            <ReadyToBook />
          ) : userData.planState === PLAN_MEETING_BOOKED ? (
            <MeetingBooked />
          ) : (
            <DataProcessing />
          )}
          <div className="data-processing-page-texts">
            {pageContent()}
            <div className="contact-text">
              If you encountered any issues or you've got something to share, drop a
              line to
              {' '}
              <a href="mailto:hello@hrmny.ai">hello@hrmny.ai</a>
            </div>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
};

export default DataProcessingPage;
