import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from 'assets/icons/logo_full.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/footer_facebook_icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/footer_linkedin_icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/footer_instagram_icon.svg';
import './footer.scss';

const Footer = ({ isLandingPage = false, noLinks = false }) => (
  <footer className={isLandingPage ? 'footer-landing' : ''}>
    <div className="logo-wrapper">
      <Logo />
    </div>
    <div className="footer-links">
      {!noLinks && (
        <div className="links-column">
          {!isLandingPage && <a href="/">Homepage</a>}
          <a href="/careers">Careers</a>
          <a href="/about-us">About us</a>
          <a
            href="https://blog.hrmny.ai/"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
          <a href="mailto:hello@hrmny.ai">Contact</a>
        </div>
      )}
      <div className="social-links-copyright">
        <div className="social-links">
          <a
            href="https://www.facebook.com/HRMNY.humantech/"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/hrmny-humantech/"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <LinkedinIcon />
          </a>
          <a
            href="https://www.instagram.com/hrmny_team/"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <InstagramIcon />
          </a>
        </div>
        <div className="copyright text-s">
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          <a href="/cookie-policy" target="_blank">
            Cookie Policy
          </a>
          <a href="/terms-conditions" target="_blank">
            Terms & Conditions
          </a>
          <span>
            &copy; HRMNY
            {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  isLandingPage: PropTypes.bool,
  noLinks: PropTypes.bool,
};

export default Footer;
