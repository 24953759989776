import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import TextInput from 'components/atoms/text-input';
import ButtonPrimary from 'components/atoms/button-primary';
import ModalWindow from 'components/molecules/modal-window';
import useHttpClient from 'utils/hooks/http-request';
import {
  EMAIL_REGEX,
  REQUIRED_MESSAGE,
  INVALID_EMAIL_MESSAGE,
} from 'utils/constants';
import api from 'utils/api';
import { ReactComponent as InviteIcon } from 'assets/icons/invite_friends.svg';
import './invite-friends-modal.scss';

const InviteFriendsModal = ({
  isModalOpened, closeModal, text, title,
}) => {
  const { sendRequest, isLoading } = useHttpClient();
  const {
    register,
    reset,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitSuccessful, touched },
  } = useForm({
    mode: 'onBlur',
    defaultValues: { email: '' },
  });

  const onSubmit = async (data) => {
    const inviteFriendsUri = api.inviteFriend();

    const postData = {
      email: data.email,
    };

    try {
      await sendRequest(inviteFriendsUri, 'POST', postData);
      reset();
    } catch (error) {
      if (error.response.data.errors.email) {
        setError('email', {
          type: 'server',
          message: 'This user has already been registered',
          shouldFocus: true,
        });
      }
    }
  };
  const inviteTitle = () => (
    <div className="invite-friends-title-block">
      <h3 className="invite-friends-title">{title}</h3>
      <p className="text-l">{text}</p>
    </div>
  );
  return (
    <ModalWindow
      isOpened={isModalOpened}
      close={closeModal}
      heading={text ? '' : 'Invite a friend'}
    >
      <div className="invite-friends-modal">
        {isSubmitSuccessful && !touched.email && (
        <p className="invite-success-text text-l">
          Your friend has been invited!
        </p>
        )}

        {text ? (inviteTitle()) : (
          <div className="invite-icon">
            <InviteIcon />
          </div>
        )}
        <TextInput
          name="email"
          inputmode="email"
          label="Friend's email address"
          placeholder="Friend's email address"
          register={register({
            required: REQUIRED_MESSAGE,
            pattern: {
              value: EMAIL_REGEX,
              message: INVALID_EMAIL_MESSAGE,
            },
          })}
          error={errors.email}
        />

        {text && (
          <p className="invite-precision text-s">
            If you want to invite multiple friends, enter email addresses
            comma separated.
          </p>
        ) }
        <ButtonPrimary
          text="Send invitation"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          isSmall
        />
      </div>
    </ModalWindow>
  );
};

InviteFriendsModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InviteFriendsModal;
